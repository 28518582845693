//
// Co3
//
// Copyright:: (c) 2023 Innotronic Ingenieurbüro GmbH

import { Controller } from '@hotwired/stimulus';
import consumer from "../channels/consumer";

export default class extends Controller
{
  static classes = [ 'online', 'offline', 'active' ];

  connect()
  {
    this.element.classList.add( this.offlineClass );

    this.channelSubscription = consumer.subscriptions.create(
      {
        channel: "DevChannel",
      },
      {
        connected:    this.channelConnect.bind( this ),
        disconnected: this.channelDisconnect.bind( this ),
        received:     this.channelReceive.bind( this )
      }
    );
  }

  disconnect()
  {
    this.channelSubscription.unsubscribe();
  }

  ping( e )
  {
    console.log( "Send PING to Dev" );
    this.channelSubscription.perform( 'ping' )
  }

  channelConnect()
  {
    console.log( 'Connected to Dev.' );
    this.element.classList.remove( this.offlineClass );
    this.element.classList.add( this.onlineClass );
  }

  channelDisconnect()
  {
    console.log( 'Disconnected from Dev.' );
    this.element.classList.remove( this.onlineClass );
    this.element.classList.add( this.offlineClass );
  }

  channelReceive( data )
  {
    console.log( `Received data from Dev: ${data}` );

    clearTimeout( this.activeTimer );
    // this.element.classList.remove( this.onlineClass );
    this.element.classList.add( this.activeClass );
    this.activeTimer = setTimeout( () =>
      {
        this.element.classList.remove( this.activeClass );
        // this.element.classList.add( this.onlineClass );
      }, 1000 );
  }
}