//
// Co3
//
// Copyright:: (c) 2022 Innotronic Ingenieurbüro GmbH

import { Controller } from '@hotwired/stimulus';
import * as bootstrap from 'bootstrap';

export default class extends Controller
{
  connect()
  {
    this.toast = new bootstrap.Toast( this.element );
    this.toast.show();
  }
}