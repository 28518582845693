//
// Co3
//
// Copyright:: (c) 2022 Innotronic Ingenieurbüro GmbH

import { Controller } from '@hotwired/stimulus';
import { get } from '@rails/request.js';

export default class extends Controller
{
  static targets = [ 'list', 'prev', 'page', 'next' ];

  initialize()
  {
    this.observerPrev = this.createPrevObserver();
    this.observedPrev = null;
    this.observerPage = this.createPageObserver();
    this.observedPage = null;
    this.observerNext = this.createNextObserver();
    this.observedNext = null;
  }

  async load( url )
  {
    if( url == null ) return;
    const response = await get( url, { contentType: "text/vnd.turbo-stream.html", responseKind: "turbo-stream" } );
    return response.ok;
  }

  createPrevObserver()
  {
    const options = { root: this.element };

    return new IntersectionObserver( items =>
    {
      items.forEach( item =>
      {
        if( item.isIntersecting )
        {
          this.observerPrev.unobserve( item.target );
          this.listTarget.scrollTo( { top: 100 } )
          const url = item.target.dataset.url;
          this.load( url );
          item.target.remove();
        }
      });
    }, options );
  }

  createPageObserver()
  {
    const options = { root: this.element };

    return new IntersectionObserver( items =>
    {
      items.forEach( item =>
      {
        if( item.isIntersecting )
        {
          const url = item.target.dataset.url;
          this.load( url );
          history.pushState( {}, '', url );
        }
      });
    }, options );
  }

  createNextObserver()
  {
    const options = { root: this.element };

    return new IntersectionObserver( items =>
    {
      items.forEach( item =>
      {
        if( item.isIntersecting )
        {
          this.observerNext.unobserve( item.target );
          const url = item.target.dataset.url;
          this.load( url );
          item.target.remove();
        }
      });
    }, options );
  }

  prevTargetConnected( element )
  {
    this.observerPrev.observe( element );
  }

  prevTargetDisconnect( element )
  {
    this.observerPrev.unobserve( element );
  }

  pageTargetConnected( element )
  {
    this.observerPage.observe( element );
  }

  pageTargetDisconnect( element )
  {
    this.observerPage.unobserve( element );
  }

  nextTargetConnected( element )
  {
    this.observerNext.observe( element );
  }

  nextTargetDisconnect( element )
  {
    this.observerNext.unobserve( element );
  }
}