//
// Co3
//
// Copyright:: (c) 2023 Innotronic Ingenieurbüro GmbH

import { Controller } from '@hotwired/stimulus';
import { Turbo } from "@hotwired/turbo-rails";

export default class extends Controller
{
  connect()
  {
    this.element.addEventListener( 'click', this.select );
  }

  // Table selected
  select( e )
  {
    let elt = e.target;

    while( true )
    {
      if( elt == null ) return;
      if( elt.nodeName == "A" ) return;
      if( elt.nodeName == "BUTTON" ) return;

      if( 'link' in elt.dataset ) break;

      elt = elt.parentElement;
    }

    let url = elt.dataset.link;

    if( 'target' in elt.dataset )
    {
      window.open( url, elt.dataset[ 'target' ] );
    }
    else if( 'turboFrame' in elt.dataset )
    {
      Turbo.visit( url, { frame: elt.dataset[ 'turboFrame' ] } );
    }
    else
    {
      Turbo.visit( url );
    }
  }
}